export const IS_LOADING = 'IS_LOADING';
export const IS_LOADED = 'IS_LOADED';
export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const tokenConfig = 'tokenConfig';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const USER_REGISTERED = 'USER_REGISTERED';
export const SAVE_OPERATORID = 'SAVE_OPERATORID';
export const USER_LOGIN = 'USER_LOGIN';
export const LOG_OUT = 'LOG_OUT';

export const GET_PROFILE = 'GET_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';

export const ORDER_CREATE = 'ORDER_CREATE';
export const GET_PAST_ORDER = 'GET_PAST_ORDER';
export const GET_CURRENT_ORDER = 'GET_CURRENT_ORDER';
export const GET_COLLECT_ORDER = 'GET_COLLECT_ORDER';
export const GET_PAYMENT_ORDER = 'GET_PAYMENT_ORDER';
export const GET_DEPOSIT_ORDER = 'GET_DEPOSIT_ORDER';

export const DRIVER_LOGIN = 'DRIVER_LOGIN';
export const DRIVER_TASK = 'DRIVER_TASK';
export const DRIVER_HISTORY = 'DRIVER_HISTORY';
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';
export const UPDATE_PICK_UP = 'UPDATE_PICK_UP';

export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ORDER_LIST = 'ORDER_LIST';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const CREATE_TASK = 'CREATE_TASK';

export const OPERATOR_LIST = 'OPERATOR_LIST';

export const READ_MESSAGE = 'READ_MESSAGE';
export const GET_INBOX = 'GET_INBOX';
export const GET_UNREAD = 'GET_UNREAD';
export const MAKE_PAYMENT = 'MAKE_PAYMENt';

export const SEND_SUPPORT = 'SEND_SUPPORT';

export const GET_LOCKER = 'GET_LOCKER';
export const OPEN_LOCKER = 'OPEN_LOCKER';
export const ADMIN_UPDATE_LOCKER = 'ADMIN_UPDATE_LOCKER';
export const LOCKER_SUMMARY = 'LOCKER_SUMMARY';
export const LOCKER_DETAILS = 'LOCKER_DETAILS';

export const GET_ENQUIRY = 'GET_ENQUIRy';
export const UPDATE_ENQUIRY = 'UPDATE_ENQUIRY';

export const GET_OVERVIEW = 'GET_OVERVIEW';
export const GET_PERFORMANCE = 'GET_PERFORMANCE';
export const GET_SERVICE_RANK = 'GET_SERVICE_RANK';
export const GET_SERVICE_LIST = 'GET_SERVICE_LIST';
export const UPDATE_SERVICE_LIST = 'UPDATE_SERVICE_LIST';
export const GET_OPERATOR_LIST = 'GET_OPERATOR_LIST';
export const UPDATE_OPERATOR_LIST = 'UPDATE_OPERATOR_LIST';
export const GET_SERVICE_PRICE = 'GET_SERVICE_PRICE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const RESEND_OTP = 'RESEND_OTP';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const CREATE_OPERATOR = 'CREATE_OPERATOR';
export const ADMIN_GET_LOCKER = 'ADMIN_GET_LOCKER';

export const CHECK_LOCATION = 'CHECK_LOCATION';
export const CHECK_ONE_LOCKER = 'CHECK_ONE_LOCKER';
export const GET_EMPTY_LOCKER = 'GET_EMPTY_LOCKER';

export const UPDATE_LOCKER_RESERVED = 'UPDATE_LOCKER_RESERVED';
export const UPDATE_LOCKER_BOOKING = 'UPDATE_LOCKER_BOOKING';
export const GET_LOCKER_BY_LOCATION = 'GET_LOCKER_BY_LOCATION';
export const GET_ALL_LOCKER_BY_LOCATION = 'GET_ALL_LOCKER_BY_LOCATION';

export const UPDATE_FABRIC_LIST = 'UPDATE_FABRIC_LIST';
export const CREATE_FABRIC = 'CREATE_FABRIC';
export const DUPLICATE_PRICING_STRATEGY = 'DUPLICATE_PRICING_STRATEGY'

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const CREATE_SERVICE_LIST = 'CREATE_SERVICE_LIST';

export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_SMALL_LOCKER = 'GET_SMALL_LOCKER';

export const CREATE_CHARGES = 'CREATE_CHARGES'
export const GET_CHARGES = 'GET_CHARGES'
export const GET_CHARGEORDER = 'GET_CHARGEORDER'
export const GET_ORDERID = 'GET_ORDERID'
export const GET_LOCATION = 'GET_LOCATION'

export const GET_PAYMENT_REPORT = 'GET_PAYMENT_REPORT'
export const GET_CATEGORY_REPORT = 'GET_CATEGORY_REPORT'
export const GET_ITEM_REPORT = 'GET_ITEM_REPORT'
export const GET_SALES_SUMMARY_REPORT = 'GET_SALES_SUMMARY_REPORT'

export const UPDATE_PRICING_STRATEGY = 'UPDATE_PRICING_STRATEGY'

export const RESET_LOCKER_STATUS = 'RESET_LOCKER_STATUS'
export const OPEN_LOCKER_STATUS = 'OPEN_LOCKER_STATUS'

export const GET_RESCHEDULE = 'GET_RESCHEDULE'
export const CREATE_RESCHEDULE = 'CREATE_RESCHEDULE'

export const GET_MDR = 'GET_MDR'
export const CREATE_MDR = 'CREATE_MDR'
export const REMOVE_MDR = 'REMOVE_MDR'

export const GET_ADMIN = 'GET_ADMIN'
export const CREATE_ADMIN = 'CREATE_ADMIN'
export const REMOVE_ADMIN = 'REMOVE_ADMIN'

export const VERIFY_TOKEN = 'VERIFY_TOKEN'

export const GET_SMS = 'GET_SMS'
export const GET_LOCATION_LIST = 'GET_LOCATION_LIST'

export const GET_REFUND_REPORT = 'GET_REFUND_REPORT'

export const GET_CODE = 'GET_CODE'
export const CREATE_CODE = 'CREATE_CODE'
export const UPDATE_CODE = 'UPDATE_CODE'
export const DELETE_CODE = 'DELETE_CODE'

export const CHECK_USER_CODE = 'CHECK_USER_CODE'

export const NEW_LOCKER_GETALL = 'NEW_LOCKER_GETALL';
export const NEW_LOCKER_UNLOCK = 'NEW_LOCKER_UNLOCK';

export const SYNC_LOCKER = 'SYNC_LOCKER'; 
