import React, { useEffect, useState } from 'react';
import backArrow from '../../assets/images/back-arrow.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getFabricPrice, getSmallLocker } from '../../redux/actions/userActions';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import { Divider } from '@mui/material';

function Home2() {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleBack = () => {
    history('/customer/home');
  };
  const lockerId = location.state.lockerId;
  const { servicePrice, small } = useSelector((state) => state.userReducer);
  const handleModalClose = () => {
    setShow(false);
  };
  // console.log('home2', location.state)
  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getFabricPrice({ strategy: location.state.strategy }));
    dispatch(getSmallLocker({ lockerId }));
    // eslint-disable-next-line
  }, []);

  const goLaundry = (price) => {
    const newData = {
      ...location.state,
      price
    }
    if (small && small.length) {
      history('/customer/washing/stepone', {
        state: newData
      })
    } else {
      setShow(true);
    }
  }

  const goNormal = (price) => {
    const newData = {
      ...location.state,
      price
    }
    history('/customer/washing/stepone', {
      state: newData

    })
  }

  return (
    <>
      <div className="home2-arrowback">
        <img src={backArrow} alt="backArrow" className='arrowbutton' onClick={handleBack} />
      </div>
      <p className="home-word2">Category</p>
      <div className="home-body-btm-main">
        <center>
          <br />
          <p className="home-word3">Please choose a Category </p>
          {/* <p className="home-word3">today?</p> */}
          <br />
          {servicePrice && servicePrice.garment.length ? <div className="category">
            {/* <img src={washandiron} alt="washandiron" className="home2-img" /> */}
            <div className="washingandirontext">
              <p style={{ marginTop: '5%' }}
                onClick={() => goNormal(servicePrice.garment)}
              >
                Garment
              </p>
            </div>
          </div> : ''}
          {servicePrice && servicePrice.household.length ? <div className="category">
            {/* <img src={ironing} alt="ironing" className="home2-img" /> */}
            <div className="washingandirontext">
              <p style={{ marginTop: '5%' }}
                onClick={() => goNormal(servicePrice.household)}
              >
                Household
              </p>
            </div>
          </div> : ''}
          {servicePrice && servicePrice.shoe.length ? <div className="category">
            {/* <img src={dryclean} alt="dryclean" className="home2-img" /> */}
            <div className="washingandirontext">
              <p style={{ marginTop: '5%' }}
                onClick={() =>
                  goNormal(servicePrice.shoe)}
              >
                Shoe
              </p>
            </div>
          </div> : ''}
          {servicePrice && servicePrice.laundry.length ? <div className="category">
            {/* <img src={shoecleaning} alt="shoecleaning" className="home2-img" /> */}
            <div className="washingandirontext">
              <p style={{ marginTop: '5%' }}
                onClick={() => goLaundry(servicePrice.laundry)}
              >
                {small && small.length ? '' : <a style={{ color: 'red' }}>!</a>} Laundry per Locker
              </p>
            </div>
          </div> : ''}
          {/* <div className="category">
            <img src={shoecleaning} alt="shoecleaning" className="home2-img" />
            <div className="washingandirontext">
              <p
                onClick={() =>
                  history('/customer/washingAndIron', {
                    state: servicePrice.shoehandbeg[0],
                  })
                }
              >
                Shoe/ Handbeg
              </p>
            </div>
          </div> */}
          {/* <div className="category">
            <img src={dettol} alt="dettol" className="home2-img" />
            <div className="washingandirontext">
              <p
                onClick={() =>
                  history('/customer/WashingAndIron', {
                    state: servicePrice.household[0],
                  })
                }
              >
                Household
              </p>
            </div>
          </div> */}
        </center>
      </div>
      <Modal
        open={show}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="home2-modal">
          <p className="order-modal-text">Message</p>
          <Divider />
          <br />
          <InputLabel>No available locker for this item type.</InputLabel>


          <button
            className="home2-modal-button"
            type="submit"
            onClick={handleModalClose}
          >
            Close
          </button>
        </Box>
      </Modal>
    </>
  );
}

export default Home2;
