import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getLocationList } from '../../redux/actions/userActions';
import queryString from "query-string";
import jsQR from "jsqr";

import backArrow from '../../assets/images/back-arrow.png';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function Location() {

    const history = useNavigate();
    const dispatch = useDispatch();
    const { locationList } = useSelector((state) => state.userReducer);

    const video = useRef();
    const canvas = useRef();
    const locationRef = useRef()

    const [playing, setPlaying] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("");
    const [touch, setTouch] = useState(false) // whether the device have touch
    const [flash, setFlash] = useState(false) // turn on or off the touch
    const [videoDevices, setVideoDevices] = useState([])

    let timer;
    // const [data, setData] = useState({
    //     state: '',
    //     city: '',
    //     lockerId: '',
    //     location: '',
    //     name: ''
    // });
    // const [stateList, setStateList] = useState({ options: [], loading: false });
    // const [cityList, setCityList] = useState({ options: [], loading: false });
    // const [nameList, setNameList] = useState({ options: [], loading: false });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getLocationList());
        openCamera()
        getVideoDevices()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (locationList) {
            locationRef.current = locationList
        }
        // eslint-disable-next-line
    }, [locationList]);

    const handleBack = () => {
        history('/customer/home');
    };

    const openCamera = (opt) => {
        // facingMode: environment/user
        const options = opt || { video: { facingMode: "environment" }, audio: false };

        navigator.mediaDevices
            .getUserMedia(options)
            .then(stream => {
                if (!stream.getVideoTracks().length) {
                    setError("Device don't have a camera")
                } else {
                    setPlaying(true)
                    video.current.srcObject = stream;
                    video.current.play();
                    requestAnimationFrame(tick);
                    const track = video.current.srcObject.getVideoTracks()[0];
                    const settings = track.getSettings();
                    if ("torch" in settings) setTouch(true)
                }
            })
            .catch(err => {
                setError(err.message)
            });
    }

    const offCamera = () => {
        if (video && video.current && video.current.srcObject) {
            if (video.current.srcObject.getTracks().length) video.current.srcObject.getTracks().forEach(x => x.stop());
            if (video.current.src) video.current.src = null;
            video.current.pause();
        }
    }

    const tick = () => {
        if (video && video.current && video.current.readyState === video.current.HAVE_ENOUGH_DATA && !loading) {
            const ctx = canvas.current.getContext("2d");
            ctx.drawImage(video.current, 0, 0, 280, 320);
            let imageData = ctx.getImageData(0, 0, 280, 320);
            let code = jsQR(imageData.data, 280, 320);

            if (code) {
                clearTimeout(timer);
                timer = null;
                setLoading(true)
                offCamera();

                const urlArray = code.data.split("?");
                let { locker } = queryString.parse(urlArray[1]);

                if (locker) {
                    const filterData = locationRef.current.filter((data) => data.id === locker)[0];
                    if (filterData) {
                        history('/customer/home2', { state: { lockerId: filterData.id, location: filterData.location, strategy: filterData.strategy, name: filterData.name } })
                    } else {
                        setError("Locker not found")
                        setLoading(false)
                        openCamera()
                    }
                } else {
                    setError("This is not a valid QR code")
                    setLoading(false)
                    openCamera()
                }
            }
        }
        timer = setTimeout(tick);
    }

    const getVideoDevices = () => {
        navigator.mediaDevices
            .enumerateDevices()
            .then(devices => {
                const videoInput = devices.filter(device => device.kind === "videoinput");
                const setThis = videoInput.map((device, index) => ({
                    deviceId: device.deviceId,
                    groupId: device.groupId,
                    kind: device.kind,
                    label: device.label ? device.label : `Camera ${index + 1}`
                }));
                setVideoDevices(setThis);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const switchCamera = (deviceId) => {
        offCamera();

        const option = {
            video: {
                deviceId: { exact: deviceId }
            },
            audio: false
        };
        navigator.mediaDevices
            .getUserMedia(option)
            .then(stream => {
                video.current.srcObject = stream;
                video.current.play();
                requestAnimationFrame(tick);
                const track = video.current.srcObject.getVideoTracks()[0];
                const settings = track.getSettings();
                if ("torch" in settings) setTouch(true)
            })
            .catch(err => setError(err.message));
    }

    const handleFlash = () => {
        if (video && video.current) {
            const track = video.current.srcObject.getVideoTracks()[0];

            track.applyConstraints({
                advanced: [
                    {
                        torch: !flash
                    }
                ]
            });
            setFlash(!flash)
        }
    }

    // useEffect(() => {
    //     if (locationList && locationList.length && !nameList.options.length) {
    //         const filterName = locationList && locationList.map((j) => j.name);
    //         setNameList({ options: filterName, loading: true });
    //     }  // eslint-disable-next-line
    // }, [locationList])
    // const setFilterCity = (i) => {
    //     const filterCity = locationList
    //         .filter((j) => j.state === i)
    //         .map((j) => j.city);
    //     setCityList({ options: filterCity, loading: true });
    //     setData({ ...data, state: i, city: "", name: "" });
    // };

    // const setFilterData = (name) => {
    //     const filterData = locationList.filter((j) => j.name === name)[0];
    //     // console.log('filterData', filterData)
    //     setData({ ...data, lockerId: filterData.id, location: filterData.location, strategy: filterData.strategy, name: filterData.name })
    // }
    // const goNext = () => {
    //     // console.log('state', data);
    //     history('/customer/home2', {
    //         state: data,
    //     })
    // }
    return (
        <>
            <div className="home2-arrowback">
                <img src={backArrow} alt="backArrow" className='arrowbutton' onClick={handleBack} />
            </div>
            <p className="home-word2">Location</p>
            <div className="home-body-btm-main">
                {/* <center> */}
                {/* <br /><br /> */}
                {/* <p className="home-word3">Please choose a Location </p> */}
                {/* <p className="home-word3">today?</p> */}
                {/* <br /> */}
                {/* <div style={{ width: '70%', marginTop: '10%' }}>
                        <img src={washandiron} alt="washandiron" className="home2-img" />
                        <div className="washingandirontext">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">STATE</InputLabel>
                                <Select label='State' value={data.state} onChange={(e) => setFilterCity(e.target.value)}>
                                    {stateList.options && stateList.options.map((state, idx) => (
                                        <MenuItem key={idx} value={state}>{state}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div> */}
                {/* {data.state ? <div style={{ width: '70%', marginTop: '10%' }}>
                        <img src={washandiron} alt="washandiron" className="home2-img" />
                        <div className="washingandirontext">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">CITY</InputLabel>
                                <Select label='City' value={data.city} onChange={(e) => setFilterName(e.target.value)}>
                                    {cityList.options && cityList.options.map((d, idx) => (
                                        <MenuItem key={idx} value={d}>{d}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div> : null} */}
                {/* <div style={{ width: '70%', marginTop: '10%' }}>
                        <img src={washandiron} alt="washandiron" className="home2-img" />
                        <div className="washingandirontext">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Branch Name</InputLabel>
                                <Select label='Branch' value={data.name} onChange={(e) => setFilterData(e.target.value)}>
                                    {nameList.options && nameList.options.map((j, idx) => (
                                        <MenuItem key={idx} value={j}>{j}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div> */}
                {/* {data.lockerId ? <div style={{ marginTop: '15%' }}>
                        <button
                            className="location-button1"
                            onClick={() => goNext()}
                            type="submit"
                        >
                            Continue
                        </button>
                    </div> : null} */}
                {/* </center> */}

                <div style={{ textAlign: "center" }}>
                    <video style={{ display: "none" }} ref={video} muted autoPlay playsInline></video>
                    <div style={{ paddingTop: "10%" }}>
                        {
                            loading ? (
                                <h6>Loading</h6>
                            ) : playing ? (
                                <div>
                                    <div>
                                        <canvas ref={canvas} style={{ border: "1px #373737 solid", borderRadius: "15px", margin: "auto", overflow: "hidden" }} height="320" width="280"></canvas>
                                    </div>

                                    <p>
                                        <strong>Scan the QR code on the locker</strong>
                                    </p>
                                    <p>
                                        <small>* switch camera if your camera is not clear</small>
                                    </p>

                                    <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select Camera</InputLabel>
                                            <Select label='Camera' onChange={(e) => switchCamera(e.target.value)}>
                                                {videoDevices.map((device, index) => (
                                                    <MenuItem key={index} value={device.deviceId}>{device.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {
                                        touch ? (
                                            <div style={{ paddingTop: "5%" }}>
                                                <button className="btn btn-primary" id="qr-touchlight" onClick={() => handleFlash()}>
                                                    {flash ? "Off touch light" : "On touch light"}
                                                </button>
                                            </div>
                                        ) : null
                                    }

                                    <div style={{ color: "red" }}><p>{error}</p></div>
                                </div>
                            ) : (
                                <h6>Please allow this webapp to access your camera</h6>
                            )
                        }
                    </div>
                </div>
            </div >
        </>
    );
}

export default Location;
