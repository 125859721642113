import { Button } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import ReactModal from "react-modal";

// e.g
// eslint-disable-next-line no-lone-blocks
{/* <AnnouncementModal
	showWithinDateRange={{
		from: new Date("2023-12-11"),
		to: new Date("2023-12-20"),
	}}
	announcement={
		<p>
			All order drop-offs from the 11/12/2023 - 20/12/2023 will experience a bit
			longer turnaround time due
			<br /> to the year-end surge in cleaning demand.
			<br />
			<br /> We appreciate your understanding during this festive season. 🙏
		</p>
	}
/>; */}

const AnnouncementModal = ({ announcement, showWithinDateRange }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (showWithinDateRange?.from && showWithinDateRange?.to) {
			// setOpen to true if the date is within the range and first time visiting the page is not today
			const today = new Date();
			const from = showWithinDateRange.from;
			const to = showWithinDateRange.to;
			if (today >= from && today <= to) {
				const lastVisit = localStorage.getItem("lastVisit");
				if (lastVisit) {
					if (new Date(lastVisit).getDate() !== today.getDate()) {
						setOpen(true);
					}
				} else {
					setOpen(true);
				}
			}
		}
	}, []);

	return (
		<ReactModal
			isOpen={open}
			style={{
				content: {
					height: "fit-content",
					margin: "auto",
					background: "white",
					borderRadius: "5px",
					padding: "20px",

					display: "flex",
					flexDirection: "column",

					textAlign: "center",
				},
			}}
		>
			<h3
				style={{
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				🔔 Announcement
			</h3>

			<p>{announcement}</p>

			<Button
				variant="contained"
				onClick={() => {
					localStorage.setItem("lastVisit", new Date());

					setOpen(false);
				}}
			>
				Okay.
			</Button>
		</ReactModal>
	);
};

export default AnnouncementModal;
